<template>
  <div class="container">
    <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
      <div v-for="item in list" :key="item.ticket_id" class="list-item" @click="onNavigator({path:'/events/ticket?ticket_id=' + item.ticket_id})">
        <div class="list-item-media">
          <van-image :src="item.meeting_cover" class="list-item-media-img" />
        </div>
        <div class="list-item-inner">
          <div class="list-item-hd">
            <div class="list-item-title">
              {{ item.meeting_name }}
            </div>
            <!-- <div class="list-item-txt ui-red">
              {{ item.status_name }}
            </div> -->
          </div>
          <div class="list-item-bd">
            <!-- <div class="list-item-txt">
              {{ item.meeting_hotline }}
            </div> -->
            <!-- <div class="list-item-txt">
              ￥ {{ item.meeting_price }}
            </div> -->
            <div class="list-item-txt">
              {{ item.meeting_date}}
            </div>
            <div class="list-item-txt">
              {{ item.meeting_address }}
            </div>
            <!-- <div class="list-item-action">
              <van-button size="mini" type="primary">去查看</van-button>
            </div> -->
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'EventsMine',
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {

  },
  methods: {
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.profile_events({ 'per-page': this.pageSize, page: this.pageCurrent })
        .then(res => {
          this.listLoading = false
          this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
          this.listFinished = this.list.length >= res._meta.totalCount
          this.listFinishedText = this.list.length ? '没有更多了' : '无可用优惠券~'
          this.pageCurrent += 1
        })
    }
  }
}
</script>

<style lang="less" scoped>

    .container{
        padding-top: 10px;
    }
  .list{

  }

  .list-item{
    padding: 10px 15px;
    background: #fff;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-media{
      margin-right: 7.5px;
      &-img{
        width: 140px;
        height: 80px;
        overflow: hidden;
        border-radius: 4px;
      }
    }
    &-title{
      font-size: 14px;
      font-weight: bold;
      color: #333;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
    &-inner{
        flex: 1;
    }
    &-bd{
      position: relative;
      .list-item-txt{
          margin-bottom: 4px;
      }
    }
    &-hd{
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-count{
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 14px;
      color: #999;
    }
    &-action{
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
</style>

